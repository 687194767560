@import '../_utilities/colors';
@import '../_utilities/mixins';

html {
  font-size: 62.5%;

  body {
    font-family: var(--primary-font-family);
    font-style: normal;
    font-weight: 400;
    font-stretch:normal;
  }
}



iframe#paymentIFrame html body #frmDiv{
  font-family: var(--primary-font-family);
  font-size: 1.6rem;

  select{
    @include globalSelectField;
    font-size: 1.6rem;
    padding: 1rem;
    border-radius: 5px;
    height: 5rem;
    background-position: 96% 55%;
    width: 100%;
    background-color: #F1F6FB;
  }

  input[type="text"]{
    @include inputField;
    font-size: 1.6rem;
    padding: 1rem;
    border-radius: 5px;
    min-width: 350px;
    background: #F1F6FB;
  }


  table.mainTable{
    max-width: 630px;

    tr{
      &.creCVV2Row{
        td:last-child{
          display: flex;
          align-items: center;
          input{
            min-width: 100px;
            margin-right: 1rem;
            @include respond(mobile-xs){
              max-width: 30px;
            }
          }
        }
      }

      &.creExpirationRow{
        td:last-child{
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
      @include respond(mobile-xs){
        display: flex;
        flex-direction: column;
        margin-bottom: 1.5rem;

        td.creAddressTwoLabel{
          display: none;
        }
      }
      td{
        padding: .300rem;

        &:first-child{
          width: 220px;
          @include respond(tablet){
            width: 145px;
          }
        }

        @include respond(mobile-sm){
          select{
            width: 94%;
          }

          input{
            min-width: 240px;
          }
        }

        @include respond(mobile-xs){
          select{
            width: 100%;
          }

          input{
            min-width: 190px;
            width: 90%;
          }
        }
      }
      td,
      label{
        font-family: var(--primary-font-family);
        font-weight: 600;
        @include respond(tablet){
          font-size: 1.2rem;
        }
      }
    }

    tr.creExpirationRow{
      select{
        width: 49%;
        min-width: auto; 
      }
    }

    tr.creButtonRow{
      td{
        display: flex;
        padding-top: 2rem;

        button{
          @include globalButton;
          border: none;
          margin-right: .5rem;

          &#completeButton{
            background: var(--primary-color-1);
          }

          &#cancelButton{
            background: #C3C4C4;
          }
        }
      }
    }
  }
}

iframe#paymentIFrame{
  form.hps-form{
   width: 550px; 
  }
}

/** CC Autopay ***/
#autoPayIFrame{
  height: 700px;

  #frmDiv{
    #theForm table{
      border: 1px solid #000;
    }
  }
}

/*** ACH PAyment ***/
.chase-iframe{
  iframe#autoPayAchIFrame{
    height: 710px;
  }
}

#theForm{
  @include respond(mobile-sm){
    transform: scale(.9);
  }
} 

#paymentInfo{
  font-family: var(--primary-font-family);

  select{
    @include globalSelectField;
    font-size: 1.6rem;
    padding: 1rem;
    width: 100%;
    border-radius: 5px;
    margin-bottom: .5rem;
    height: 5rem;
    background-position: 96% 55%;
    max-width: 354px;
    background-color: #F1F6FB;
    @include respond(mobile-sm){
      max-width: 100%;
    }
  }

  input[type="text"],
  input[type="tel"]{
    @include inputField;
    font-size: 1.6rem;
    padding: 1rem;
    width: 100%;
    border-radius: 5px;
    max-width: 328px;
    background: #F1F6FB;

    @include respond(mobile-sm){
      box-sizing: border-box;
      max-width: 100%;
    }
  }

  .hps-field-group{
    margin-bottom: .6rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;

    #whatRoutingNumber, 
    #whatAccountNumber{
      &:hover{
        & > span{
          width: 100px;
          left: 320px;
          
        }

        #routingNumberDescription,
        #accountNumberDescription{
          left: 320px;
          top: 20px;

          @include respond(mobile-sm){
            left: -13rem;
            top: 2.5rem;
          }
        }
      }
    }

    #routingNumberInfo,
    #accountNumberInfo{
      left: 320px;
      top: 35px;
      z-index: 99;
    }

    @include respond(mobile-sm){
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 1rem;
      box-sizing: border-box;

      #whatRoutingNumber, 
      #whatAccountNumber{
        position: absolute;
        left: 13rem;
        top: -0.4rem;

        &:hover{
          & > span{
            width: 100px;
          }
        }

        #routingNumberInfo,
        #accountNumberInfo{
          left: -130px;
          top: 4rem;
          z-index: 99;
        }
      }
    }

    label{
      font-size: 1.6rem;
      width: 200px;
      margin-right: 1rem;
    }
  } 
}

.hps-form-group{
  margin-top: 1rem; 

  button{
    @include globalButton;
    border: none;
    padding: 1.5rem 2rem;
    font-size: 1.4rem;

    &#completeButton{
      background: var(--primary-color-1);
      margin-right: 1rem;
      float: left;
    } 

    &#cancelButton{
      background: #C3C4C4;
      display: none;
    }
  }
}

#nachaFieldset{
  padding: 0 .5rem;
  legend{
    line-height: 0;
  }

  @include respond(mobile, tablet){
    overflow-y: scroll;
    height: 70px;
  }
}

#nachaFieldset,
#nachaText{
  font-family: var(--primary-font-family);
  font-size: 1.4rem;
  line-height: 2rem;
}

iframe#paymentIFrame{
  height: 900px;
  background: transparent;
} 

